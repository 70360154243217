<script lang="ts" setup>
import OnboardingBtn from "~/components/onboarding/OnboardingBtn.vue";
import OnboardingDiscountBanner from "~/components/onboarding/OnboardingDiscountBanner.vue";
import OnboardingSkipButton from "~/components/onboarding/OnboardingSkipButton.vue";
import OnboardingStylingChanges from "~/components/onboarding/utils/OnboardingStylingChanges.vue";
import type { AgeRangeScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";
import { useFunnelStore } from "~/stores/useFunnelStore";
import OnboardingFoodBanner from "~/components/onboarding/OnboardingFoodBanner.vue";

type Props = {
  screen?: AgeRangeScreen;
  registrationParams?: RegistrationParams;
  showDiscountBanner?: boolean;
  showSkipButtons?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  showDiscountBanner: false,
  showSkipButtons: true,
  screen: undefined,
  registrationParams: undefined,
});

const isAlternativeFunnel = useFunnelStore().isAlternativeVariant();

const skipButtonActive = props.showSkipButtons && !isAlternativeFunnel;
const showHeader = isAlternativeFunnel;

interface Emits {
  (
    e: "next",
    value: {
      params: Partial<RegistrationParams>;
      nextScreenId: string | undefined;
    },
  ): void;
}

const emit = defineEmits<Emits>();

function selectOption() {
  emit("next", {
    params: {},
    nextScreenId: props.screen?.nextStep,
  });
}
</script>

<template>
  <div>
    <OnboardingStylingChanges
      use-transparent-background
      hide-progress
      :hide-header="!showHeader"
    ></OnboardingStylingChanges>
    <OnboardingSkipButton v-if="skipButtonActive" :style="'close'"></OnboardingSkipButton>

    <div class="column !tw-mt-8 !tw-flex !tw-flex-col !tw-items-center !tw-gap-8">
      <img
        v-if="!showHeader"
        src="https://assets.yazio.com/frontend/images/web-funnels/layout/yazio-logo-rounded.svg"
        alt="YAZIO Logo"
        height="36"
      />
      <div v-else class="mt-5"></div>

      <OnboardingDiscountBanner v-if="showDiscountBanner" />
      <OnboardingFoodBanner />

      <div class="!tw-flex !tw-flex-col !tw-items-center !tw-gap-3">
        <h1 class="text-header-md text-white !tw-m-0 !tw-text-center">{{ $t("text.webfunnel.age_group") }}</h1>

        <div class="!tw-flex !tw-flex-wrap !tw-justify-center !tw-gap-4">
          <OnboardingBtn
            is-column
            translation-key="text.webfunnel.age_group_1"
            image="https://images.yazio.com/frontend/web-funnels/onboarding/illustrations/age-group-1.png"
            :full-width="false"
            @click="selectOption()"
          />

          <OnboardingBtn
            is-column
            translation-key="text.webfunnel.age_group_2"
            image="https://images.yazio.com/frontend/web-funnels/onboarding/illustrations/age-group-2.png"
            :full-width="false"
            @click="selectOption()"
          />

          <OnboardingBtn
            is-column
            translation-key="text.webfunnel.age_group_3"
            image="https://images.yazio.com/frontend/web-funnels/onboarding/illustrations/age-group-3.png"
            :full-width="false"
            @click="selectOption()"
          />

          <OnboardingBtn
            is-column
            translation-key="text.webfunnel.age_group_4"
            image="https://images.yazio.com/frontend/web-funnels/onboarding/illustrations/age-group-4.png"
            :full-width="false"
            @click="selectOption()"
          />
        </div>
      </div>

      <OnboardingSkipButton v-if="skipButtonActive"></OnboardingSkipButton>
    </div>
  </div>
</template>
