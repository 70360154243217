<script lang="ts" setup>
import { ref } from "vue";
const config = useRuntimeConfig();
interface Props {
  images: string[];
  srcImageWidth: number;
  stackMaxWidth: number;
  alt: string;
}

const props = defineProps<Props>();

const selectedKey = ref<number | null>();

function select(key: number) {
  selectedKey.value = key;
}

function fullUrl(src: string) {
  return `${config.public.imageBaseUrl}/${src}.jpg?w=${props.srcImageWidth}&h=${props.srcImageWidth}&crop-to-fit`;
}
</script>

<template>
  <div
    class="image-stack"
    :style="{
      maxWidth: stackMaxWidth ? `${stackMaxWidth}px` : undefined,
    }"
  >
    <img
      v-for="(src, key) in images"
      :key="key"
      :class="{ 'z-3': key === selectedKey }"
      class="image-stack__img"
      :src="fullUrl(src)"
      :alt="alt"
      @click="select(key)"
    />
  </div>
</template>
