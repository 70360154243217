import { storeToRefs } from "pinia";
import type { OnboardingScreen } from "~/types/onboarding/onboardingScreen";
import type { Instructions } from "~/types/instructions";

import OnboardingSingleChoice from "~/components/onboarding/OnboardingSingleChoice.vue";
import OnboardingMultiChoice from "~/components/onboarding/OnboardingMultiChoice.vue";
import OnboardingReason from "~/components/onboarding/OnboardingReason.vue";
import OnboardingAffirmation from "~/components/onboarding/OnboardingAffirmation.vue";
import OnboardingSex from "~/components/onboarding/OnboardingSex.vue";
import OnboardingBirthday from "~/components/onboarding/OnboardingBirthday.vue";
import OnboardingHeight from "~/components/onboarding/OnboardingHeight.vue";
import OnboardingCurrentWeight from "~/components/onboarding/OnboardingCurrentWeight.vue";
import OnboardingActivityLevel from "~/components/onboarding/OnboardingActivityLevel.vue";
import OnboardingTargetWeight from "~/components/onboarding/OnboardingTargetWeight.vue";
import OnboardingLoadingPersonalPlan from "~/components/onboarding/OnboardingLoadingPersonalPlan.vue";
import OnboardingEventDate from "~/components/onboarding/OnboardingEventDate.vue";
import OnboardingInfoList from "~/components/onboarding/OnboardingInfoList.vue";
import OnboardingDiet from "~/components/onboarding/OnboardingDiet.vue";
import OnboardingPartner from "~/components/onboarding/OnboardingPartner.vue";
import OnboardingIllustrationsRecipes from "~/components/onboarding/OnboardingIllustrationsRecipes.vue";
import OnboardingVoucherCode from "~/components/onboarding/OnboardingVoucherCode.vue";
import OnboardingAgeRange from "~/components/onboarding/OnboardingAgeRange.vue";
import OnboardingReasonVariant from "~/components/onboarding/OnboardingReasonVariant.vue";

export function useOnboarding(instructions: Instructions) {
  const { $pinia } = useNuxtApp();

  const onboardingStore = useOnboardingStore($pinia);
  const route = useRoute();

  const { updateRegistrationParams } = onboardingStore;
  const { registrationParams } = storeToRefs(onboardingStore);

  const forceLoadingScreen = ref(false);
  const screenId = computed(() => {
    const loadingScreenId = forceLoadingScreen.value
      ? instructions.screens.find((screen) => screen.type === "loading_personal_plan")?.id
      : null;

    return loadingScreenId || route.params.id || instructions.start;
  });

  const screens: { [key: string]: OnboardingScreen } = {};
  instructions.screens.forEach((item) => {
    screens[item.id] = item;
  });

  const componentMap: { [key: string]: Component } = {
    single_choice: OnboardingSingleChoice,
    multi_choice: OnboardingMultiChoice,
    reason: OnboardingReason,
    reason_variant: OnboardingReasonVariant,
    affirmation: OnboardingAffirmation,
    sex: OnboardingSex,
    birthday: OnboardingBirthday,
    height: OnboardingHeight,
    current_weight: OnboardingCurrentWeight,
    activity_level: OnboardingActivityLevel,
    target_weight: OnboardingTargetWeight,
    loading_personal_plan: OnboardingLoadingPersonalPlan,
    date: OnboardingEventDate,
    info_list: OnboardingInfoList,
    diet: OnboardingDiet,
    illustrations_recipes: OnboardingIllustrationsRecipes,
    partner: OnboardingPartner,
    voucher_code: OnboardingVoucherCode,
    age_range: OnboardingAgeRange,
  };

  const screen = computed<OnboardingScreen>(() => {
    const _screen = screens[screenId.value as string];
    if (!_screen) {
      throw new Error(`No screen config found for id: ${screenId.value}`);
    }
    return _screen;
  });

  const screenComponent = computed<Component>(() => {
    const _screenComponent = componentMap[screen.value.type];
    if (!_screenComponent) {
      throw new Error(`No component found for type: ${screen.value.type}`);
    }
    return _screenComponent;
  });

  const screenHasWideWrapper = computed(() =>
    ["affirmation", "loading_personal_plan", "info_list", "illustrations_recipes"].includes(screen.value.type),
  );

  const screenUseTransparentBackground = computed(() => screen.value.config?.useTransparentBackground || false);

  function getProgress() {
    const screenIndex = instructions.screens.findIndex((screen) => screen.id === screenId.value);
    const totalScreens =
      instructions.screens.length -
      instructions.screens.filter((screen) => screen.type === "loading_personal_plan").length;

    return Math.round((screenIndex / totalScreens) * 100);
  }

  function checkForLoadingScreen(nextScreen: string) {
    if (screens[nextScreen]?.type === "loading_personal_plan") {
      forceLoadingScreen.value = true;
      return true;
    }

    return false;
  }

  return {
    screen,
    screenComponent,
    screens,
    registrationParams,
    updateRegistrationParams,
    screenHasWideWrapper,
    screenUseTransparentBackground,
    forceLoadingScreen,
    getProgress,
    checkForLoadingScreen,
  };
}
