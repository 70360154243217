<script lang="ts" setup>
import OnboardingBtn from "~/components/onboarding/OnboardingBtn.vue";
import type { ScreenOptionWithNextStep, SingleChoiceScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";

interface Props {
  screen: SingleChoiceScreen;
}

const props = defineProps<Props>();

interface Emits {
  (
    e: "next",
    value: {
      params: Partial<RegistrationParams>;
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();
const { addActionEvent } = useTrackingStore();

function selectOption(option: ScreenOptionWithNextStep<string>) {
  emit("next", {
    params: {},
    nextScreenId: option.nextStep,
  });

  addActionEvent({
    name: props.screen.eventName,
    selection: [option.trackingName],
    screenType: props.screen.type,
  });
}
</script>

<template>
  <div>
    <h1 class="text-header-md">
      {{ $t(screen.titleTranslationKey) }}
    </h1>
    <div class="gap-3 !tw-grid">
      <OnboardingBtn
        v-for="(option, key) in screen.options"
        :key="key"
        :emoji="option.emoji"
        :translation-key="option.translationKey"
        :description-translation-key="option.descriptionTranslationKey"
        @click="selectOption(option)"
      />
    </div>
  </div>
</template>
