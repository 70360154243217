<script setup lang="ts">
import Text from "~/components/atoms/Text.vue";

const onboardingStore = useOnboardingStore();

const { foodName } = storeToRefs(onboardingStore);
</script>

<template>
  <div v-if="foodName">
    <div class="tw-flex tw-gap-4 tw-rounded-t-xl tw-bg-yz-green-100 tw-p-5">
      <img alt="" src="https://assets.yazio.com/frontend/images/web-funnels/icons/food-calculator-icon.svg" />
      <div class="tw-flex tw-flex-col">
        <Text bold>{{ foodName }}</Text>
        <Text size="small" translation-key="text.webfunnel.food_traffic.banner.heading"></Text>
      </div>
    </div>

    <div class="tw-rounded-b-xl tw-bg-yz-green-200 tw-p-5">
      {{ $t("text.webfunnel.food_traffic.banner.subheading") }}
    </div>
  </div>
</template>
