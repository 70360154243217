<script lang="ts" setup>
const config = useRuntimeConfig();
interface Props {
  src: string;
  size?: "MEDIUM" | "LARGE";
}

const props = withDefaults(defineProps<Props>(), {
  size: "MEDIUM",
});

const fullUrl = computed(() => {
  const width = 1920;
  return `${config.public.imageBaseUrl}/${props.src}.light.png?w=${width}`;
});
</script>

<template>
  <div
    class="bg-image"
    :class="`bg-image--${size.toLowerCase()}`"
    :style="{
      backgroundImage: `url(${fullUrl})`,
    }"
  />
</template>
