<script setup lang="ts">
import { ref } from "vue";

const { locale } = useNuxtApp().$i18n;
const { code } = storeToRefs(useDiscountStore());
const helloFreshDiscountCode = "hf-wzu9p";
const isGermanHelloFreshCampaign = computed<boolean>(() => {
  return code.value === helloFreshDiscountCode && locale.value === "de";
});

const recipes = ref([
  {
    title: "Tex-Mex Fajita-Pfanne",
    image: "https://images.yazio.com/frontend/app-lp/partner/hellofresh-recipe-9009.jpg?w=700",
    description:
      "Deine Extraportion Eiweiß für starke Muskeln: Entdecke dieses leckere High Protein Gericht mit mindestens 20% Protein.",
  },
  {
    title: "Seehecht auf Curry-Linsengemüse",
    image: "https://images.yazio.com/frontend/app-lp/partner/hellofresh-recipe-9012.jpg?w=700",
    description:
      "Weniger Kohlenhydrate, voller Geschmack! Genieße dieses leckere Low Carb Gericht mit weniger als 50 g Kohlenhydraten pro Portion.",
  },
  {
    title: "Perlencouscous-Salat",
    image: "https://images.yazio.com/frontend/app-lp/partner/hellofresh-recipe-9013.jpg?w=700",
    description:
      "Für alle, die es frisch und bunt lieben: Mit mehr als 240 g Gemüse pro Portion ist dieses Gericht ein leckerer, ausgewogener Genuss.",
  },
]);
</script>

<template>
  <div v-if="isGermanHelloFreshCampaign" class="!tw-flex !tw-flex-col !tw-gap-4">
    <h1 class="!tw-m-0 !tw-text-center !tw-text-2xl !tw-text-white">Entdecke die Fit & Vital Rezepte von HelloFresh</h1>
    <div>
      <div
        v-for="recipe in recipes"
        :key="recipe.title"
        class="!tw-mb-4 !tw-flex !tw-flex-col !tw-items-center !tw-rounded-xl !tw-bg-white md:!tw-flex-row"
      >
        <img
          :src="recipe.image"
          :alt="recipe.title"
          class="!tw-max-h-96 !tw-w-full !tw-rounded-t-xl !tw-object-cover md:!tw-h-auto md:!tw-w-60 md:!tw-rounded-none md:!tw-rounded-s-lg"
        />
        <div class="!tw-flex !tw-flex-col !tw-justify-between !tw-p-4 !tw-leading-none">
          <h2 class="xs:!tw-text-center !tw-mb-2 !tw-text-lg !tw-text-yz-blue-900 lg:!tw-text-left">
            {{ recipe.title }}
          </h2>
          <p
            class="xs:!tw-text-center !tw-mb-0 !tw-pb-0 !tw-font-normal !tw-leading-5 !tw-text-yz-blue-900 lg:!tw-text-left"
          >
            {{ recipe.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
