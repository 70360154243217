<script lang="ts" setup>
import OnboardingBtn from "~/components/onboarding/OnboardingBtn.vue";
import type { NextStep, ReasonScreen, ScreenOptionWithNextStep } from "~/types/onboarding/onboardingScreen";
import { goalMap } from "~/types/onboarding/onboardingGoal";
import type { GoalTypeAbbr } from "~/types/onboarding/onboardingGoal";

interface Props {
  screen: ReasonScreen;
}

const props = defineProps<Props>();

interface Emits {
  (
    e: "next",
    value: {
      params: { reason: GoalTypeAbbr | null };
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();
const { addActionEvent } = useTrackingStore();

function selectFallbackOption() {
  emit("next", {
    params: { reason: null },
    nextScreenId: props.screen.fallbackOption.nextStep,
  });
}

function selectOption(option: ScreenOptionWithNextStep<NextStep>) {
  const goalType = option.nextStep.condition.branch;
  const reason = goalMap[goalType];

  emit("next", {
    params: { reason },
    nextScreenId: option.nextStep.nextStep,
  });

  addActionEvent({
    name: props.screen.eventName,
    selection: [option.trackingName],
    screenType: props.screen.type,
  });
}
</script>

<template>
  <div>
    <h1 class="text-header-md">
      {{ $t(screen.titleTranslationKey) }}
    </h1>
    <div class="gap-3 !tw-grid">
      <OnboardingBtn
        v-for="(option, key) in screen.options"
        :key="key"
        :emoji="option.emoji"
        :translation-key="option.translationKey"
        @click="selectOption(option)"
      />
      <OnboardingBtn
        v-if="screen.fallbackOption"
        :emoji="screen.fallbackOption.emoji"
        :translation-key="screen.fallbackOption.translationKey"
        @click="selectFallbackOption"
      />
    </div>
  </div>
</template>
