<script lang="ts" setup>
import OnboardingBtn from "~/components/onboarding/OnboardingBtn.vue";
import type { GoalTypeAbbr } from "~/types/onboarding/onboardingGoal";
import OnboardingDiscountBanner from "~/components/onboarding/OnboardingDiscountBanner.vue";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";
import type { ReasonVariantScreen } from "~/types/onboarding/onboardingScreen";

type Props = {
  screen?: ReasonVariantScreen;
  registrationParams?: RegistrationParams;
  showDiscountBanner?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  showDiscountBanner: false,
  screen: undefined,
  registrationParams: undefined,
});

interface Emits {
  (
    e: "next",
    value: {
      params: Partial<RegistrationParams>;
      nextScreenId: string | undefined;
    },
  ): void;
}

const emit = defineEmits<Emits>();

function selectOption(reason: GoalTypeAbbr) {
  emit("next", {
    params: {
      reason,
    },
    nextScreenId: props.screen?.nextStep,
  });
}
</script>

<template>
  <div>
    <h1 class="text-header-md">{{ $t("text.webfunnel.partnership.firstpage.heading") }}</h1>
    <div class="mb-3" v-html="$t('text.webfunnel.partnership.firstpage.subheading')"></div>

    <div class="gap-3 !tw-grid">
      <OnboardingDiscountBanner v-if="showDiscountBanner" />

      <OnboardingBtn
        translation-key="text.webfunnel.partnership.firstpage.answer.lose10kg"
        @click="selectOption('lose')"
      />
      <OnboardingBtn
        translation-key="text.webfunnel.partnership.firstpage.answer.lose20kg"
        @click="selectOption('lose')"
      />
      <OnboardingBtn
        translation-key="text.webfunnel.partnership.firstpage.answer.lose30kg"
        @click="selectOption('lose')"
      />
      <OnboardingBtn
        translation-key="text.webfunnel.partnership.firstpage.answer.maintain"
        @click="selectOption('maintain')"
      />
      <OnboardingBtn
        translation-key="text.webfunnel.partnership.firstpage.answer.build_muscle"
        @click="selectOption('build_muscle')"
      />
    </div>

    <p class="container mt-5 !tw-flex !tw-flex-col !tw-items-center">
      <small class="text-secondary text-body-md" v-html="$t('text.webfunnel.partnership.firstpage.existing_user')" />
      <NuxtLink to="/account/login">
        <small class="text-body-md" v-html="$t('text.webfunnel.partnership.firstpage.existing_user_link')" />
      </NuxtLink>
    </p>
  </div>
</template>
