<script lang="ts" setup>
import { ref } from "vue";
import AppDateInput from "~/components/app/AppDateInput.vue";
import OnboardingStickyButtonWrapper from "~/components/onboarding/OnboardingStickyButtonWrapper.vue";
import type { EventDateScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";

const dayjs = useDayjs();

interface Props {
  screen: EventDateScreen;
  registrationParams: RegistrationParams;
}

const props = defineProps<Props>();

interface Emits {
  (
    e: "next",
    value: {
      params: { eventDate: string };
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();

const eventDate = ref<string>(props.registrationParams.eventDate || "");

const today = computed(() => dayjs().set("hours", 0).set("minutes", 0).set("seconds", 0).format("YYYY-MM-DD"));

const maxDate = computed(() =>
  dayjs().endOf("year").add(200, "year").set("hours", 0).set("minutes", 0).set("seconds", 0).format("YYYY-MM-DD"),
);

function next() {
  emit("next", {
    params: { eventDate: eventDate.value },
    nextScreenId: props.screen.nextStep,
  });
}
</script>

<template>
  <OnboardingStickyButtonWrapper
    :button-disabled="!eventDate"
    :button-text="$t('registration.general.next')"
    @button-click="next"
  >
    <h1 class="text-header-md">
      {{ $t("onboarding.encouraging_flow.personal_goal.event_date.title") }}
    </h1>

    <AppDateInput v-model="eventDate" class="mt-5" autocorrect :min-date="today" :max-date="maxDate" />
  </OnboardingStickyButtonWrapper>
</template>
