<script lang="ts" setup>
import OnboardingBtn from "~/components/onboarding/OnboardingBtn.vue";
import type { ActivityLevelScreen } from "~/types/onboarding/onboardingScreen";
import type { ActivityDegree } from "~/types/onboarding/registrationParams";

interface Props {
  screen: ActivityLevelScreen;
}

const props = defineProps<Props>();

interface Emits {
  (
    e: "next",
    value: {
      params: { activityDegree: ActivityDegree };
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();

function selectOption(activityDegree: ActivityDegree) {
  emit("next", {
    params: { activityDegree },
    nextScreenId: props.screen.nextStep,
  });
}
</script>

<template>
  <div>
    <h1 class="text-header-md">
      {{ $t("registration.activity.headline") }}
    </h1>
    <div class="mb-4">
      {{ $t("registration.activity.teaser") }}
    </div>
    <div class="gap-3 !tw-grid">
      <OnboardingBtn
        emoji="🧑‍💻"
        translation-key="user.activity.option.low"
        description-translation-key="user.activity.description.low"
        @click="selectOption('low')"
      />
      <OnboardingBtn
        emoji="🧑‍🏫"
        translation-key="user.activity.option.normal"
        description-translation-key="user.activity.description.normal"
        @click="selectOption('moderate')"
      />
      <OnboardingBtn
        emoji="🧑‍💼"
        translation-key="user.activity.option.high"
        description-translation-key="user.activity.description.high"
        @click="selectOption('high')"
      />
      <OnboardingBtn
        emoji="👷"
        translation-key="user.activity.option.veryhigh"
        description-translation-key="user.activity.description.veryhigh"
        @click="selectOption('very_high')"
      />
    </div>
  </div>
</template>
