<script setup lang="ts">
type Props = {
  hideHeader?: boolean;
  useTransparentBackground?: boolean;
  hideProgress?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  hideHeader: false,
  useTransparentBackground: false,
  hideProgress: false,
});

if (props.hideHeader) {
  useHead(
    computed(() => ({
      style: [
        {
          children: `
          header,
          footer {
            display: none;
          }
        `,
        },
      ],
    })),
  );
}

if (props.useTransparentBackground) {
  useHead(
    computed(() => ({
      style: [
        {
          children: `
          main {
            background-size: cover;
            background-position: top;
            background-image: url(https://images.yazio.com/frontend/web-funnels/mobile-blurred-background.png);
            flex-grow: 1;
          }

          @media (min-width: 576px) {
            main {
              background-image: url(https://images.yazio.com/frontend/web-funnels/desktop-blurred-background.png);
            }
          }
        `,
        },
      ],
    })),
  );
}

if (props.hideProgress) {
  useHead(
    computed(() => ({
      style: [
        {
          children: `
          div.progress-wrapper {
            display: none !important;
          }
        `,
        },
      ],
    })),
  );
}
</script>

<template>
  <slot />
</template>
