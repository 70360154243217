<script lang="ts" setup>
interface Props {
  progress: number;
}

defineProps<Props>();

async function handleSkipButton() {
  await useOnboardingStore().skipOnboarding();
}
</script>

<template>
  <div class="progress-wrapper gap-3 !tw-flex !tw-items-center">
    <div class="flex-grow-1 progress" role="progressbar">
      <div class="progress-bar" :style="{ width: `${progress}%` }" />
    </div>

    <div>
      <div class="btn text-body-sm !tw-p-0" @click="handleSkipButton">{{ $t("text.webfunnel.skip_button") }}</div>
    </div>
  </div>
</template>
