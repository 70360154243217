<script lang="ts" setup>
import type { PartnerScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";
import OnboardingAgeRange from "~/components/onboarding/OnboardingAgeRange.vue";
import OnboardingHelloFresh from "~/components/onboarding/OnboardingHelloFresh.vue";

interface Props {
  screen: PartnerScreen;
}

const props = defineProps<Props>();

interface Emits {
  (
    e: "next",
    value: {
      params: Partial<RegistrationParams>;
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();

function doNext(value: { params: Partial<RegistrationParams>; nextScreenId: string | undefined }) {
  const { params, nextScreenId } = value;

  const defaultNextScreen = partnerPageABTestActive
    ? (props.screen.config?.alternativeNextStep ?? props.screen.nextStep)
    : props.screen.nextStep;

  emit("next", {
    params,
    nextScreenId: nextScreenId ?? defaultNextScreen,
  });
}

const partnerPageABTestActive = false;
</script>

<template>
  <div class="!tw-flex !tw-flex-col !tw-gap-8">
    <OnboardingAgeRange show-discount-banner show-skip-buttons @next="doNext" />

    <p class="!tw-mb-0 !tw-flex !tw-flex-col !tw-items-center">
      <small class="!tw-text-white" v-html="$t('text.webfunnel.partnership.firstpage.existing_user')" />
      <NuxtLink to="/account/login">
        <small class="!tw-text-gray-200" v-html="$t('text.webfunnel.partnership.firstpage.existing_user_link')" />
      </NuxtLink>
    </p>

    <OnboardingHelloFresh />
  </div>
</template>
