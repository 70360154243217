<script setup lang="ts">
type Props = {
  style?: "button" | "close";
};

withDefaults(defineProps<Props>(), {
  style: "button",
});

async function skipOnboarding() {
  await useOnboardingStore().skipOnboarding();
}
</script>

<template>
  <button
    v-if="style === 'button'"
    id="quizSkipButton"
    class="btn border-1 w-75 border-white text-white"
    @click="skipOnboarding"
  >
    {{ $t("text.webfunnel.skip_button") }}
  </button>
  <div v-else class="!tw-absolute !tw-right-8 !tw-top-8 !tw-cursor-pointer" @click="skipOnboarding">
    <img alt="" src="https://assets.yazio.com/frontend/images/web-funnels/layout/close-button-white.svg" />
  </div>
</template>

<style scoped>
.btn {
  @media (max-width: 567px) {
    width: 100% !important;
  }
}
</style>
