<script lang="ts" setup>
import type { OnboardingScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";

interface Props {
  screenComponent: Component;
  screen: OnboardingScreen;
  registrationParams: Partial<RegistrationParams>;
  hasWideWrapper: boolean;
  useTransparentBackground: boolean;
}
const props = defineProps<Props>();

interface Emits {
  (
    e: "next",
    value: {
      nextScreenId: string;
      params: Partial<RegistrationParams>;
    },
  ): void;
}
defineEmits<Emits>();
</script>

<template>
  <div :class="{ 'onboarding-transparent-background': useTransparentBackground }">
    <div class="container-fluid pb-3">
      <div class="row !tw-justify-center">
        <section class="col-12 px-0">
          <transition name="fade" mode="out-in">
            <component
              :is="screenComponent"
              :screen="screen"
              :registration-params="registrationParams"
              :has-wide-wrapper="props.hasWideWrapper"
              @next="$emit('next', $event)"
            />
          </transition>
        </section>
      </div>
    </div>
  </div>
</template>
