<script lang="ts" setup>
import { ref } from "vue";
import type { LoadingPersonalPlanScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";

interface Props {
  screen: LoadingPersonalPlanScreen;
}

const props = defineProps<Props>();

interface Emits {
  (
    e: "next",
    value: {
      params: Partial<RegistrationParams>;
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();

const texts = [
  {
    emoji: "🥞",
    description: "onboarding.encouraging_flow.plan.load_plan_1.description",
  },
  {
    emoji: "🧘",
    description: "onboarding.encouraging_flow.plan.load_plan_2.description",
  },
  {
    emoji: "⏲️",
    description: "onboarding.encouraging_flow.plan.load_plan_3.description",
  },
  {
    emoji: "🤝",
    description: "onboarding.encouraging_flow.plan.load_plan_4.description",
  },
];

const titleTextKey = ref<string>("onboarding.encouraging_flow.plan.load_plan_1.title");
const descriptionTextKey = ref<string>(texts[0].description);
const emojiText = ref<string>(texts[0].emoji);

function next() {
  emit("next", {
    params: {},
    nextScreenId: props.screen.nextStep,
  });
}

const timeout = ref<ReturnType<typeof setTimeout> | null>(null);
const waitTimesList = navigator.userAgent.includes("playwright-test")
  ? [100, 100, 100, 100]
  : [...useAppConfig().onboardingLoadingPersonalPlanMS];

function loopText() {
  if (texts.length === 0) {
    return next();
  }

  const { description, emoji } = texts.shift() ?? { description: "", emoji: "" };
  const wait = waitTimesList.shift() ?? 500;

  emojiText.value = emoji;
  descriptionTextKey.value = description;

  timeout.value = setTimeout(loopText, wait);
}

onUnmounted(() => (timeout.value ? clearTimeout(timeout.value) : null));
onMounted(loopText);
</script>

<template>
  <div class="onboarding-content container gap-4 pt-5 !tw-flex !tw-flex-col">
    <div class="bg-shape-10 !tw-h-[60%]" />
    <div class="!tw-text-center">
      <BSpinner variant="primary" />
    </div>

    <h2 class="!tw-text-center">{{ $t(titleTextKey) }}</h2>

    <div class="container-sm" style="max-width: 20rem">
      <div class="card border-white shadow">
        <div class="card-body !tw-text-center">
          <div class="display-4 mb-3 mt-3">{{ emojiText }}</div>
          <div>{{ $t(descriptionTextKey) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
