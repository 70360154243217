<script setup lang="ts">
import { usePartnerContent } from "~/composables/usePartnerContent";

const { t } = useNuxtApp().$i18n;

const partnerContent = await usePartnerContent();
const discount = await useAsyncData("discount", () => useDiscountStore().getCurrentDiscount());

const exclusiveLabel = computed(() => {
  return partnerContent.data.value
    ? t("text.webfunnel.partnership.firstpage.exclusive_offer", [partnerContent.data.value.name])
    : t("app.profunnel.paywall.plans.bar.no_trial_discount");
});

const discountLabel = computed(() => t("text.webfunnel.partnership.firstpage.discount_forever", [discount.data.value]));

const image = computed(() => {
  if (partnerContent.data.value?.image_url) {
    return `${partnerContent.data.value.image_url}?width=400`;
  }

  return "https://assets.yazio.com/frontend/images/pro-signup/discount-percentage-badge.svg";
});
</script>

<template>
  <div
    v-if="partnerContent.data.value || discount.data.value !== 0"
    class="card bg-secondary-subtle-light border-0 shadow-sm"
  >
    <div
      :class="{
        'tw-opacity-25': partnerContent.status.value === 'pending',
      }"
      class="card-body !tw-flex !tw-w-full !tw-items-stretch !tw-p-0 !tw-text-left"
      disabled="disabled"
    >
      <div
        :style="`background-image: url(${image})`"
        class="partner-image"
        :class="{
          default: !partnerContent.data.value?.image_url,
        }"
      ></div>
      <div class="p-3 !tw-flex !tw-flex-col">
        <span>{{ exclusiveLabel }}</span>
        <span class="fw-bold fs-5 text-danger">{{ discountLabel }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.partner-image {
  background-size: cover;
  background-position: center top;
  border-radius: 0.75rem 0 0 0.75rem;
  width: 5.5rem;
  background-repeat: no-repeat;

  &.default {
    background-size: 4rem;
    background-position: center center;
  }
}
</style>
