<script lang="ts" setup>
import { ref } from "vue";
import AppDateInput from "~/components/app/AppDateInput.vue";
import OnboardingStickyButtonWrapper from "~/components/onboarding/OnboardingStickyButtonWrapper.vue";
import type { BirthdayScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";

interface Props {
  screen: BirthdayScreen;
  registrationParams: RegistrationParams;
}

const props = defineProps<Props>();
const { getMinBirthdate, getMaxBirthdate } = useAge();

interface Emits {
  (
    e: "next",
    value: {
      params: { dateOfBirth: string };
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();

const dateOfBirth = ref<string>(props.registrationParams.dateOfBirth || "");

function next() {
  emit("next", {
    params: { dateOfBirth: dateOfBirth.value },
    nextScreenId: props.screen.nextStep,
  });
}
</script>

<template>
  <OnboardingStickyButtonWrapper
    :button-disabled="!dateOfBirth"
    :button-text="$t('registration.general.next')"
    @button-click="next"
  >
    <h1 class="text-header-md">
      {{ $t("registration.birthday.headline") }}
    </h1>
    <div class="mb-3">{{ $t("registration.birthday.info.teaser") }}</div>
    <AppDateInput
      v-model="dateOfBirth"
      years-reversed
      :min-date="getMinBirthdate()"
      :min-date-error-message="$t('registration.safety_check.age.too_high')"
      :max-date="getMaxBirthdate()"
      :max-date-error-message="$t('app.profunnel.funnel.registration.safety_check.age.too_low')"
    />
  </OnboardingStickyButtonWrapper>
</template>
